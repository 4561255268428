export default [
    {
        title: 'Dashboard',
        icon: 'HomeIcon',
        route: 'dashboard',
      },
      {
        title: 'Nuovo Progetto',
        icon: 'CheckSquareIcon',
        route: 'new_project_type'
      },
    
      {
        title: 'Progetti Salvati',
        icon: 'SaveIcon',
        route: 'saved_projects'
      },
    
      {
        title: 'Clienti',
        icon: 'GridIcon',
        route: 'clients'
      },
      {
        title: 'Manuale utente',
        icon: 'BookOpenIcon',
        route: 'manual'
      },
]
