<template>
  <layout-vertical>
    <template #navbar="{ toggleVerticalMenuActive }">
      <div class="d-flex flex-grow-1 align-items-center justify-content-between px-1">
        <div class="d-flex flex-row align-items-center">
          <ul class="nav navbar-nav d-xl-none mr-2">
            <li class="nav-item">
              <b-link class="nav-link" @click="toggleVerticalMenuActive">
                <feather-icon icon="MenuIcon" size="21" />
              </b-link>
            </li>
          </ul>

          <span v-if="!$route.meta.backend" slot class="mb-0">
            Tipo Account<br />
            <b class="text-uppercase">{{ userData.account_type_label }}</b>
          </span>

          <span v-if="!$route.meta.backend" slot class="mb-0 ml-2">
            Licenza<br />
            <b class="text-uppercase">{{ userData.subscription_plan_name }}</b> / Scadenza: <strong>{{ endDate
            }}</strong>
          </span>

          <b-link v-if="!$route.meta.backend" to="/pricing">
            <b-button class="ml-2" variant="primary" size="sm">Acquista</b-button>
          </b-link>

          <span v-if="!$route.meta.backend" slt class="ml-3">
            <small>
              Crediti residui<br />
              <template
                v-if="userData.account_type_code == 'installatore' || userData.account_type_code == 'installatore_manutentore'">
                Nuovo impianto: <strong>{{ userData.bought_credit_new > 200 ? 'Illimitati' : userData.bought_credit_new }}</strong><br />
              </template>
              <template
                v-if="userData.account_type_code == 'manutentore' || userData.account_type_code == 'installatore_manutentore'">
                Manutenzione impianto: <strong>{{ userData.bought_credit_maintenance > 200 ? 'Illimitati' : userData.bought_credit_maintenance }}</strong>
              </template>
              <template
                v-if="userData.account_type_code == 'utilizzatore'">
                Analisi impianto: <strong>{{ userData.bought_credit_maintenance > 200 ? 'Illimitati' : userData.bought_credit_maintenance }}</strong>
              </template>
            </small>
          </span>

        </div>

        <b-nav>
          <b-nav-item-dropdown id="user_menu" variant="flat-primary" class="dropdown-icon-wrapper" right
            menu-class="w-100" no-caret>
            <template #button-content>
              <div v-if="!$route.meta.backend">
                <div class="d-flex align-items-center">
                  <div class="text-dark">
                    <p class="mb-0 text-right">
                      <b>{{ userData.user_name }} {{ userData.user_surname }}</b>
                      <br />
                      {{ userData.company_name }}
                    </p>
                  </div>

                  <div>
                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary ml-1"
                      style="height: 3rem; width: 3rem">
                      <span class="text-white feather icon-user h2 mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="d-flex align-items-center">
                  <div class="text-dark">
                    <p class="mb-0 text-right">
                      <b>Amministratore</b>
                    </p>
                  </div>

                  <div>
                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary ml-1"
                      style="height: 3rem; width: 3rem">
                      <span class="text-white feather icon-user h2 mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <b-dropdown-item v-if="!$route.meta.backend" @click="$router.push('/profile')">Account</b-dropdown-item>
            <b-dropdown-divider v-if="!$route.meta.backend" />
            <b-dropdown-item @click="$route.meta.backend ? adminLogout() : logout()">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </div>
    </template>

    <router-view />
  </layout-vertical>
</template>

<script>
import {
  BButton,
  BNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BLink,
} from "bootstrap-vue";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import VerticalNavMenu from "@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    BButton,
    BNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BLink,
    VerticalNavMenu,
  },

  async mounted() {
    const userdata = localStorage.getItem("user");
    try {
      this.userData = JSON.parse(userdata);
    } catch(e) {

    }
    setInterval(() => {
      const userdata = localStorage.getItem("user");
      try {
        this.userData = JSON.parse(userdata);
      } catch(e) {
        
      }
    }, 1000);
  },


  data() {
    return {
      interval: null,
      userData: {},
      menuVisible: false,
      dateFormatter: new Intl.DateTimeFormat("it-IT", {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      })
    };
  },

  computed: {
    endDate: function () {
      try {
        return this.dateFormatter.format(Date.parse(this.userData.data_end));
      } catch (e) {
        return this.userData.data_end;
      }
    }
  },

  methods: {
    logout() {
      localStorage.removeItem("access_token");
      if (!localStorage.getItem("remember_me")) {
        localStorage.removeItem("user");
      }

      this.$router.push("/login");
    },

    adminLogout() {
      localStorage.removeItem("admin_access_token");
      this.$router.push("/backend");
    },
  },
};
</script>

<style scoped>
#user_menu:hover {
  background-color: transparent;
}
</style>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
</style>
