export default [
  {
    title: 'Elenco Account',
    icon: 'UserIcon',
    route: 'list_account_backend',
  },
  {
    title: 'Gestione licenze',
    icon: 'FileTextIcon',
    route: 'backend_licences',
  },
  {
    title: 'Gestione blocchi',
    icon: 'FileTextIcon',
    route: 'backend_block_settings',
  },
  {
    title: 'Gestione agenti',
    icon: 'UserIcon',
    route: 'backend_agents',
  },
  {
    title: 'Clienti segnalati',
    icon: 'UsersIcon',
    route: 'backend_agent_customers',
  },
  {
    title: 'Elenco Pagamenti',
    icon: 'DollarSignIcon',
    route: 'backend_payments',
  },
  {
    title: 'Pagamenti non finalizzati',
    icon: 'AlertTriangleIcon',
    route: 'backend_lost_payments',
  },
]
